//
// user.scss
// Use this to write your custom SCSS
//
body {
    height: 100%;
}

html {
    height: 100%;
}

#root {
    height: 100%;
}

#LoginAlert {
    position: absolute;
    bottom: 10px;
    width: 100%;
    z-index: 1;
    .alert {
        text-align: center;
        padding: 0.3rem 0.75rem;
    }
    .alert-hidden {
        display: none;
    }
}

.navbar-brand-img {
    background-position: center;
    width: 100%;
    height: 50px;
    background-image: url("../img/black_square_solid_mi_2x.png");
    background-repeat: no-repeat;
    background-size: contain;
    min-width: 1.75rem;
    @include media-breakpoint-down(md) {
        background-image: url("../img/mitel_logo_long.svg");
        height: 30px;
        max-height: 30px;
    }
}

.tableContainer {
    @include media-breakpoint-down(md) {
        padding-bottom: 20px;
        margin-bottom: 15px;
    }
}

.loginText {
    margin-top: 10px;
    margin-bottom: 30px;
    @include media-breakpoint-down(md) {
        margin-bottom: 60px;
    }
}

.navbar-brand-img.mx-auto {
    @include media-breakpoint-down(md) {
        width: 300px;
        max-height: 40px;
    }
}

.needAssistance {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
}

.login.logoContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
}

.loginLogo {
    width: 225px;
    // max-width: 80%;
    // @include media-breakpoint-down(md) {
    //   width: 300px;
    // }
}

// Add a margin bottom on 'Dashboard' title if the home dash page is mobile/sm
.header-title {
    @include media-breakpoint-down(md) {
        margin-bottom: 20px;
    }
}

// Cursor Pointers
.navbar-vertical-sm.navbar-expand-md .navbar-brand-img {
    cursor: pointer;
}

.navbar-nav .nav-link {
    cursor: pointer;
}

//Date should center when on sm but be right/end on md>
.dateString {
    text-align: center;
    padding-top: 15px;
    @include media-breakpoint-up(md) {
        text-align: end;
    }
}

.brandLogoBackground {
    fill: white;
}

.bold {
    font-weight: bold;
}

.bolder-font {
    font-weight: 800;
}

.circle {
    width: 1.8rem;
    height: 1.8rem;
    display: inline-flex;
    background-color: grey;
    border-radius: 50%;
    margin-right: 4px;
    justify-content: center;
    align-items: center;
}

.circle .circle-text {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-expand {
    @each $breakpoint,
    $value in $grid-breakpoints {
        &-#{$breakpoint} {
            @include media-breakpoint-up(#{$breakpoint}) {
                .navbar-collapse {
                    visibility: visible !important;
                }
            }
        }
    }
}

.navbar-vertical .navbar-nav .nav-link[aria-expanded] {
    &:after {
        display: block;
        content: "\e92e";
        font-family: "Feather";
        margin-left: auto;
        transition: transform 0.2s;
    }
    // Expanded
    &[aria-expanded="true"] {
        &:after {
            transform: rotate(-180deg);
        }
    }
}

.form-control .grey {
    background-color: red;
    color: red;
}

.statsPage {
    padding-bottom: 100px;
}

.seperator {
    margin-bottom: 15px;
    margin-top: 15px;
    border-bottom: 1px solid #e3ebf6;
}

.doughnut_wrapper {
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#CSVUploadBtn {
    transform: scale(1);
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover {
        color: var(--bs-green) !important;
        transform: scale(1.3);
        transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        cursor: pointer;
    }
}

#NewSoundTaskBtn {
    transform: scale(1);
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    &:hover {
        color: var(--bs-green) !important;
        transform: scale(1.3);
        transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        cursor: pointer;
    }
}

.green-circle {
    border-radius: 100%;
    width: 10px;
    height: 10px;
    background-color: #00d97e;
}

.popup {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(128, 128, 128, 0.295);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .content {
        min-height: 200px;
    }
}

.cancelSTBtn {
    overflow: visible;
    height: 100%;
    &:hover {
        cursor: pointer;
    }
}

.tableFilter {
    background-color: white;
    border: none;
    border-radius: 3px;
}

#LoginBtn {
    background-color: #7c69ef !important;
    border: #7c69ef;
    &:hover {
        cursor: pointer;
        background-color: #b5a9ff !important;
    }
}

.btn-primary {
    background-color: #7c69ef !important;
    border: #7c69ef;
    &:hover {
        cursor: pointer;
        background-color: #b5a9ff !important;
    }
}


/* The switch - the box around the slider */

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    padding: 0px !important;
}


/* Hide default HTML checkbox */

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0px;
}


/* The slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #95aac9;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    padding: 0px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #00D97E;
}

input:focus+.slider {
    box-shadow: 0 0 1px #00D97E;
}

input:checked+.slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}


/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.dropShadow {
    box-shadow: 3px 3px 5px rgba(189, 189, 189, 0.473);
}

#form1 {
    background-color: red !important;
}

.changePassword:hover{
    cursor: pointer;
}